<template>
    <div class="zhong">
        <hd-com></hd-com>
        <div class="banner">
            <div class="banner-text">
                <h3>{{ herbalDetail.herbalPrescriptionName }}</h3>
                <p>
                    当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><router-link to="/Drug/Herbal">中药方剂</router-link>
                    <i class="el-icon-arrow-right"></i><a>{{ herbalDetail.herbalPrescriptionName }}</a>
                </p>
                <div class="banner-right">
                    <span @click="onCompatibility(herbalDetail)">加入配伍</span>
                    <span v-if="!exist" @click="onCollection(herbalDetail)">加入收藏</span>
                    <span v-else @click="deleteAll()">取消收藏</span>
                </div>
            </div>
        </div>
        <!--    内容部分    ?-->
        <div class="content-wrap">
            <div class="content clearfix">
                <el-scrollbar class="left-nav-wrap">
                    <!-- 具体要包裹的内容 -->
                    <div class="fast-nav">
                        <ul class="timeline is-reverse">
                            <li class="timeline-item" v-if="tableData != null" @click="jump(0)">
                                <div class="timeline-item__tail"></div>
                                <div class="timeline-item__node">
                                    <!---->
                                </div>
                                <!---->
                                <div class="timeline-item__text">
                                    <!---->
                                    <h3 data-size="18">组成</h3>
                                </div>
                            </li>
                            <li class="timeline-item" v-for="(item, index) in herbalDetail.infos" :key="index"
                                @click="jump(index + 1)">
                                <div class="timeline-item__tail"
                                    v-if="!herbalDetail.herbalPrescriptionCategory && index != herbalDetail.infos.length - 1">
                                </div>
                                <div class="timeline-item__tail" v-if="herbalDetail.herbalPrescriptionCategory">
                                </div>
                                <div class="timeline-item__node"></div>
                                <div class="timeline-item__text">
                                    <h3 data-size="18">{{ item.title }}</h3>
                                </div>
                            </li>
                            <li class="timeline-item" v-if="herbalDetail.herbalPrescriptionCategory"
                                @click="jump(herbalDetail.infos.length + 1)">
                                <div class="timeline-item__node">
                                </div>
                                <div class="timeline-item__text">
                                    <h3 data-size="18">同类药</h3>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-scrollbar>
                <div class="detail-box">
                    <div class="sd-div" v-if="tableData != null">
                        <h3 class="sd-tit overview" data-size="26">组成</h3>
                        <div class="sd-mes">
                            <el-table :data="tableData" border stripe style="width: 100%">
                                <el-table-column prop="compositionName" label="成分" width="180">
                                    <template slot-scope="scope">
                                        <div data-size="14" v-html="scope.row.compositionName"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="dosage" label="用量" width="180">
                                    <template slot-scope="scope">
                                        <div data-size="14" v-html="scope.row.dosage"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="recommendDosage" label="推荐用量" width="180">
                                    <template slot-scope="scope">
                                        <div data-size="14" v-html="scope.row.recommendDosage"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="remark" label="炮制方法">
                                    <template slot-scope="scope">
                                        <div data-size="14" v-html="scope.row.remark"></div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="sd-div" v-for="(item, index) in herbalDetail.infos" :key="index">
                        <h3 class="sd-tit" data-size="24">{{ item.title }}</h3>
                        <div class="sd-mes">
                            <p data-size="16" v-html="replace(item.info)"></p>
                        </div>
                    </div>
                    <div class="sd-div" v-if="herbalDetail.herbalPrescriptionCategory">
                        <h3 class="sd-tit" data-size="24">同类药</h3>
                        <el-collapse v-for="(drugRelation, index) in drugRelations" :key="index">
                            <el-collapse-item :title="drugRelation[0].relationType" name="index" v-if="drugRelation[0].relationType != '中成药'">
                                <template>
                                    <ul class="zhongyao clearfix" v-if="drugRelation[0].relationType == '中药饮片'">
                                        <li class="zhongyao-item"
                                            v-for="(drugRelationitem, index) in drugRelation[0].items.slice(0, 8)"
                                            :key="index" @click="toMaterialDetail(drugRelationitem.hashKey)">
                                            <div class="zhongyao-img">
                                                <img v-if="drugRelationitem.itemPhoto" :src="drugRelationitem.itemPhoto"
                                                    alt="" />
                                                <img v-else src="../../assets/img/zyc_img.png" alt="" />
                                            </div>
                                            <p class="zhongyao-name">
                                                {{ drugRelationitem.itemName }}
                                            </p>
                                        </li>
                                        <a class="more-btn" v-if="drugRelation[0].items.length >= 8"
                                            @click="toMaterialMore(drugRelation[0].categoryId)">更多...</a>
                                    </ul>
                                    <ul class="hxsw" v-if="drugRelation[0].relationType == '中药方剂'">
                                        <li v-for="(drugRelationitem, index) in drugRelation[0].items" :key="index">
                                            <!-- <el-button type="success" plain @click="toHerbalDetail(drugRelationitem.hashKey)">
                                                {{ drugRelationitem.itemName }}
                                            </el-button> -->
                                            <div class="hxswsucBtn" @click="toHerbalDetail(drugRelationitem.hashKey)">
                                                  {{
                                                    drugRelationitem.itemName
                                                }}
                                            </div>
                                        </li>
                                        <a class="more-btn" v-if="drugRelation[0].HasMore"
                                            @click="toHerbalMore(drugRelation[0].categoryId)">更多...</a>
                                    </ul>
                                    <ul class="hxsw" v-if="drugRelation[0].relationType == '食疗药膳'">
                                        <li v-for="(drugRelationitem, index) in drugRelation[0].items" :key="index">
                                            <!-- <el-button type="success" plain @click="toFoodDetail(drugRelationitem.hashKey)">
                                                {{ drugRelationitem.itemName }}
                                            </el-button> -->
                                            <div class="hxswsucBtn" @click="toFoodDetail(drugRelationitem.hashKey)">
                                                  {{
                                                    drugRelationitem.itemName
                                                }}
                                            </div>
                                        </li>
                                        <a class="more-btn" v-if="drugRelation[0].HasMore"
                                            @click="toFoodMore(drugRelation[0].categoryId)">更多...</a>
                                    </ul>
                                    <ul class="hxsw" v-if="drugRelation[0].relationType == '中成药'">
                                        <li v-for="(drugRelationitem, index) in drugRelation[0].items" :key="index">
                                            <!-- <el-button type="success" plain @click="toDrugDetail(drugRelationitem.hashKey)">{{
                                                    drugRelationitem.itemName
                                            }}</el-button> -->
                                               <div class="hxswsucBtn" @click="toDrugDetail(drugRelationitem.hashKey)">
                                                  {{
                                                    drugRelationitem.itemName
                                                }}
                                            </div>
                                        </li>
                                        <a class="more-btn" v-if="drugRelation[0].HasMore"
                                            @click="toDrugMore(drugRelation[0].categoryId, drugRelation[0].relationType)">更多...</a>
                                    </ul>
                                    <ul class="hxsw" v-if="drugRelation[0].relationType == '化学药品和生物制品'">
                                        <li v-for="(drugRelationitem, index) in drugRelation[0].items" :key="index">
                                            <!-- <el-button type="success" plain @click="toDrugDetail(drugRelationitem.hashKey)">{{
                                                    drugRelationitem.itemName
                                            }}</el-button> -->
                                                <div class="hxswsucBtn" @click="toDrugDetail(drugRelationitem.hashKey)">
                                                  {{
                                                    drugRelationitem.itemName
                                                }}
                                            </div>
                                            
                                        </li>
                                        <a class="more-btn" v-if="drugRelation[0].HasMore"
                                            @click="toDrugMore(drugRelation[0].categoryId, drugRelation[0].relationType)">更多...</a>
                                    </ul>
                                </template>
                            </el-collapse-item>
                        </el-collapse>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
        <staticTime></staticTime>
    </div>
</template>

<script>
import hdCom from "../../components/header";
import ftCom from "../../components/footer";
import rtBar from "../../components/right";
import staticTime from "../../components/staticTime";
import { Herbal } from '../../components/Drug/Herbal'
import { DrugExtend } from "../../components/Drug/DrugExtend"
import { toMaterial, toFood, toHerbal, toDrug, toMaterialMore, toFoodMore, toHerbalMore, toDrugMore } from "../../utils/relation";
import { Collection } from "../../components/collection/Collection"
import { addCompare, getCompatibilityList } from '../../utils/Compatibility'
export default {
    name: "HerbalDetail",
    components: {
        hdCom,
        ftCom,
        rtBar,
        staticTime
    },
    data() {
        var herbal = new Herbal(this.TokenClient, this.Services.Drug)
        var drugExtend = new DrugExtend(this.TokenClient, this.Services.Drug)
        var collection = new Collection(this.TokenClient, this.Services.Statics)
        return {
            hashKey: this.$route.query.hashKey, //列表页id
            tableData: [], //初始化组成表格数据
            herbalDetail: {}, //初始化详情
            drugRelations: {}, //初始化同类药
            herbalDomain: herbal,
            drugExtendDomain: drugExtend,
            scroll: '',
            collectionDomain: collection,
            entityData: {},
            fontSize: '',
            exist: false,
            collectionId: ""
        };
    },
    methods: {
        getFontSize(data) {
            this.fontSize = data
        },
        handleScroll() {
            let scrollY = document.documentElement.scrollTop ||
                window.pageYOffset ||
                document.body.scrollTop;
            let scrollObj = document.querySelector(".left-nav-wrap");
            let banner = document.querySelector(".banner");
            //scrollObj 这个是下图右边答题卡的div

            if (scrollY > 80) {
                banner.style.position = "fixed";
                banner.style.top = 0;
                banner.style.zIndex = 99;
                scrollObj.style.position = "fixed";
                scrollObj.style.top = "230px";
            } else {
                banner.style.position = "initial";
                banner.style.top = "none";
                scrollObj.style.position = "absolute";
                scrollObj.style.top = "auto";
            }
        },
        checkExist() {
            var _this = this;
            _this.collectionDomain.Exist(this.entityData.type, this.entityData.hashKey, this.entityData.id,
                function (data) {
                    _this.exist = data.data.exist
                    _this.collectionId = data.data.collectionId

                }, function (err) {

                });
        },
        //详情获取
        getHerbalDetail: function () {
            var _this = this;
            let params = `${this.hashKey}`;
            _this.herbalDomain.Detail(params, function (data) {
                _this.herbalDetail = data.data;
                _this.tableData = data.data.compositions;
                _this.tableData.map(item => {
                    return item.compositionName = item.compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, '<a href="#/Drug/MaterialDetail?hashKey=$3">$1</a>')
                })
                if (data.data.herbalPrescriptionCategory != null) {
                    //获取同类药
                    _this.getRelation(
                        data.data.herbalPrescriptionCategory.categoryName
                    );
                }
                _this.entityData = {
                    type: "herbal",
                    name: _this.herbalDetail.herbalPrescriptionName,
                    hashKey: _this.herbalDetail.hashKey,
                    id: _this.herbalDetail.herbalPrescriptionId
                }
                _this.checkExist()
                _this.$bus.$emit('fontSize');
            },
                function (error) {
                    console.log("中药方剂详情获取失败:", error)
                })
        },
        //获取同类药
        getRelation: function (herbalCategoryName) {
            var _this = this;
            _this.drugExtendDomain.Relation(herbalCategoryName, function (data) {
                _this.drugRelations = data.data;
            },
                function (error) {
                    console.log("同类药请求异常", error)
                })
        },
        deleteAll: function () {
            var _this = this;
            var idarr = [_this.collectionId]
            _this.collectionDomain.Delete(idarr, function (data) {
                _this.checkExist()
                _this.$message({
                    center: true,
                    type: 'success',
                    message: '取消成功!'
                });
            },
                function (error) {
                    console.log(error);
                })
        },
        onCompatibility: function (item) {
            var compatibility = getCompatibilityList();
            if (compatibility.length > 0) {
                if (compatibility.some(i => i.hashKey == item.hashKey && i.type == '2')) {
                    this.$message.error('已加入用药审查！');
                    return false;
                }
            }
            addCompare(item.hashKey, item.herbalPrescriptionName, 1, "herbal");
            this.$message.success('成功加入用药审查！');
            this.$bus.$emit('right', 'reload')
        },
        //收藏
        onCollection: function (item) {
            var _this = this;
            _this.collectionDomain.Put("herbal", item.herbalPrescriptionName, item.hashKey, item.herbalPrescriptionId, 0,
                function (data) {
                    _this.checkExist()
                    _this.$message({
                        type: 'success',
                        message: '收藏成功!'
                    });
                }, function (err) {
                    if (err.status === 400) {
                        _this.$message.error('请勿重复收藏');
                    }
                });
        },
        //跳转同类药-中药更多
        toMaterialMore(cateid) {
            toMaterialMore(cateid);
        },
        //跳转同类药-中药详情
        toMaterialDetail(hashKey) {
            toMaterial(hashKey);
        },
        //跳转同类药-食疗药膳更多
        toFoodMore(cateid) {
            toFoodMore(cateid);
        },
        //跳转同类药-食疗药膳详情
        toFoodDetail(hashKey) {
            toFood(hashKey);
        },
        //跳转同类药-中药方剂更多
        toHerbalMore(cateid) {
            toHerbalMore(cateid);
        },
        //跳转同类药-中药方剂详情
        toHerbalDetail(hashKey) {
            this.hashKey = hashKey
            this.getHerbalDetail();
        },
        //跳转同类药-中西药更多
        toDrugMore(cateid, type) {
            toDrugMore(cateid, type);
        },
        //跳转同类药-中西药详情
        toDrugDetail(hashKey) {
            toDrug(hashKey);
        },
        replace: function (content) {
            return content.replace(/\n/g, '</p><p>');
        },
        dataScroll: function () {
            this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
        },
        jump(index) {
            let jump = document.getElementsByClassName('sd-div');
            // 获取需要滚动的距离
            let total = jump[index].offsetTop - 80;
            window.scroll(0, total)
            // Chrome
            //document.body.scrollTop = total;
            // Firefox
            //document.documentElement.scrollTop = total;
            // Safari
            //window.pageYOffset = total;
            // $('html, body').animate({
            // 'scrollTop': total
            // }, 400);

        },
        loadSroll: function () {
            var self = this;
            var $navs = $(".timeline .timeline-item");
            var sections = document.getElementsByClassName('sd-div');
            for (var i = sections.length - 1; i >= 0; i--) {
                if (self.scroll >= sections[i].offsetTop - 80) {
                    $navs.eq(i).addClass("active").siblings().removeClass("active")
                    break;
                }
            }
        }
    },
    created() {
        let _this = this;
        window.toMaterialDetail = _this.toMaterialDetail;
    },
    watch: {
        scroll: function () {
            this.loadSroll();
        },
    },
    mounted() {
        this.$bus.$emit('select', 'herbal')
        window.addEventListener("scroll", this.handleScroll);
        window.addEventListener("scroll", this.dataScroll);
        this.getHerbalDetail();
    },
    destroyed() {
        //销毁监听
        window.removeEventListener("scroll", this.handleScroll);
        window.removeEventListener("scroll", this.dataScroll);
    },
};
</script>

<style scoped>
@import "../../assets/css/header.css";
@import "../../assets/css/footer.css";

/*banner*/
.hxswsucBtn {
    display: inline-block;
		padding: 5px 15px;
		margin: 7px 12px 5px 0;
		border-radius: 2px;
		font-size: 14px;
		color: #b76c12;
		background-color: #FFFFFF;
		border: 1px solid #b76c12;
        cursor: pointer;
}
.hxswsucBtn:hover {
    display: inline-block;
		padding: 5px 15px;
		margin: 7px 12px 5px 0;
		border-radius: 2px;
		font-size: 14px;
		color: #f3f0eb;
		background-color: #b76c12;
		border: 1px solid #b76c12;
        cursor: pointer;
}
.banner {
    width: 100%;
    height: 160px;
    background: url("../../assets/img/zyk_banner.jpg")center no-repeat;
}

.banner-text {
    position: relative;
    width: 1200px;
    margin: 0 auto;
}

.banner-text h3 {
    padding-top: 45px;
    font-size: 26px;
    font-weight: normal;
    color: #ffffff;
}

.banner-text h3 span {
    font-size: 14px;
}

.banner-text p {
    margin-top: 10px;
    font-size: 14px;
    color: #ffffff;
}

.banner-text p a {
    color: #ffffff;
    font-size: 14px;
    margin: 0 5px;
}

.banner-right {
    position: absolute;
    right: 5px;
    top: 65px;
}

.banner-right span {
    display: inline-block;
    margin-left: 20px;
    width: 132px;
    height: 32px;
    border: 1px solid #ffffff;
    border-radius: 32px;
    box-sizing: border-box;
    text-align: center;
    color: #ffffff;
    line-height: 30px;
    cursor: pointer;
}

.banner-right span:hover {
    background-color: #ffffff;
    color: #b76c12;
}

/*内容部分*/
.content-wrap {
    background: #f8f8f8;
}

.content-wrap .content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 60px;
    background-color: rgba(255, 255, 255, 0);
}

.detail-box {
    margin-right: 260px;
    padding: 0px 35px;
    width: 830px;
    background: #ffffff;
    box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
}

.sd-div {
    padding: 30px 0;
    border-top: 1px solid #e5e5e5;
}

.sd-div:first-child {
    border-top: 0px;
}

.sd-div .sd-tit {
    font-size: 24px;
    color: #333333;
    font-weight: normal;
    margin-bottom: 30px;
}

.sd-div .sd-tit.overview {
    font-size: 26px;
    color: #333333;
    padding-left: 15px;
    border-left: 3px solid #333;
    line-height: 26px;
}

.sd-div .sd-mes p {
    white-space: pre-line;
}

.hxsw li {
    margin: 2px 0;
    display: inline-block;
    height: 42px;
    /* border: 1px solid #999999; */
    padding: 0 5px;
    box-sizing: border-box;
}

.hxsw li a {
    line-height: 42px;
    text-align: center;
    color: #666666;
    font-size: 16px;
    cursor: pointer;
}

.hxsw li:hover {
    border-color: #00ab98;
}

.hxsw li:hover a {
    color: #00ab98;
}

.zhong .hxsw li:hover {
    border-color: #b76c12;
}

.zhong .hxsw li:hover a {
    color: #b76c12;
}

.zhongyao {
    text-align: center;
}

.zhongyao li {
    float: left;
    width: 184px;
    height: 184px;
    margin: 10px;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
}
.zhongyao /deep/ li:hover {
    border-color: #b76c12;
    cursor: pointer;
}

.zhongyao li .zhongyao-img {
    width: 120px;
    height: 120px;
    margin: 15px auto 5px;
}

.zhongyao li .zhongyao-img img {
    width: 100%;
    height: auto;
}

.zhongyao li .zhongyao-name {
    font-size: 18px;
    text-align: center;
    color: #333333;
}

/* .zhongyao li:hover {
    border-color: #00ab98;
    cursor: pointer;
}

.zhongyao li:hover .zhongyao-name {
    color: #00ab98;
} */

.zhongyao .more-btn {
    display: inline-block;
}

.zhong .more-btn {
    margin: 10px auto;
    display: inline-block;
    width: 124px;
    height: 38px;
    line-height: 38px;
    background-color: #b76c12;
    color: #ffffff !important;
    text-align: center;
    cursor: pointer;
}

.left-nav-wrap {
    position: absolute;
    margin-left: 950px;
    width: 260px;
    /* border: 1px solid #e5e5e5; */
    box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
    background: #FFFFFF;
}

::v-deep .el-scrollbar .el-scrollbar__wrap {
    max-height: 470px;
    overflow-x: hidden;
}

.fast-nav {
    width: 250px;
    box-sizing: border-box;
    padding: 30px 0 30px 40px;
    z-index: 10;
}

.timeline-item {
    position: relative;
    padding-bottom: 20px;
    padding-top: 10px;
    cursor: pointer;
}

.timeline-item__tail {
    position: absolute;
    top: 15px;
    left: 3px;
    height: 100%;
    border-left: 1px solid #e5e5e5;
}

.timeline-item__node {
    position: absolute;
    background-color: #cccccc;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 15px;
    left: -1px;
    width: 9px;
    height: 9px;
}

.timeline-item__text {
    position: relative;
    padding-left: 28px;
    top: -3px;
}

.timeline-item__text h3 {
    font-size: 18px;
    color: #333;
    font-weight: normal;
}

.timeline-item.active .timeline-item__node,
.timeline-item:hover .timeline-item__node {
    background-color: #b76c12;
}

.timeline-item.active .timeline-item__text h3,
.timeline-item:hover .timeline-item__text h3 {
    color: #b76c12;
}

.zhong .food-list .food-item {
    position: relative;
    padding: 15px 20px;
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-shadow: 3px 0 5px rgba(0, 0, 0, 0.08);
    cursor: pointer;
}

.zhong .food-list .food-item:hover {
    box-shadow: 3px 2px 8px rgba(0, 0, 0, 0.2);
}

.zhong .food-tit {
    font-size: 18px;
    font-weight: normal;
}

.zhong .food-tit span {
    font-size: 12px !important;
    color: #fff;
    background-color: #b76c12;
    border-radius: 10px;
    height: 18px;
    line-height: 18px;
    margin-left: 10px;
    padding: 0 4px;
    display: inline-block;
    position: relative;
    top: -2px;
}

.zhong .food-item p {
    margin-top: 10px;
    color: #666666;
}

.zhong .food-item p em {
    font-style: normal;
}

.zhong /deep/ .el-collapse-item__header {
    background-color: #b76c12;
    color: #ffffff;
    font-size: 18px;
    padding: 0 20px;
}

.zhong /deep/ .el-collapse-item__content {
    padding: 15px 5px;
}
</style>
<style>

.food .el-collapse-item__header {
    background-color: #b76c12;
    color: #ffffff;
    font-size: 18px;
    padding: 0 20px;
}

.food .el-collapse-item__header:hover {
    color: #ffffff;
}

.food .el-collapse-item__wrap {
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
}

.food .el-collapse-item__content {
    padding: 15px 5px;
}

.el-table td div a {
    color: #b76c12;
}

.el-table td div a:hover {
    text-decoration: underline;
}

.el-scrollbar__wrap {
    overflow: auto;
}
</style>
